/* page content */
.page {
	.news1, .news {
		.date {
			color: #ffba08;
		}
	}
	.page_nav {
		.active {
			color: #ffba08;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #ffba08;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #ffba08;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .h2 a {
        font-size: 16px;
        color: #ffba08;
    }
	@media screen and (max-width: 1024px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img, h2 img, h3 img, h4 img, h5 img, h6 img, p a img, strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
        #page-text p a img {
            width: auto!important;
            max-width: 100%!important;
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    height: 455px;
    position: relative;
    font-size: 16px;
    padding: 265px 0 0 0;
    margin-bottom: 80px;
    line-height: 1;
	ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 155px;
        left: 50%;
        margin-left: -570px;
		li {
			padding: 5px 0px;
			color: #fff;
			@include inline-block;
			a {
                text-decoration: none;
                color: #ffba08;
                &:hover {
                    color: #fff;
                }
			}
			&:after {
                content: '\f105';
                font-family: 'fontAwesome';
				margin: 0px 10px 0px 11px;
				color: #fff;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
    }
    h1 {
        color: #fff;
    }
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &.second {
        height: auto;
        padding: 155px 0 0 0;
        margin-bottom: 35px;
        ul {
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
            li {
                color: #999999;
                &:after {
                    color: #999999;
                }
                a {
                    &:hover {
                        color: #999999;
                    }
                }
            }
        }
        h1 {
            font-size: 48px;
            color: #1c3144;
            margin: 50px 0 0 0;
        }
    }
    @media screen and (max-width: 1440px) {
        padding-top: 220px;
        height: 370px;
        margin-bottom: 50px;
        ul {
            top: 125px;
        }
        &.second {
            padding-top: 115px;
            h1 {
                font-size: 38px;
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: 1170px) {
        ul {
            left: 15px;
            margin: 0;
        }
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 32px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 0;
        height: auto;
        font-size: 14px;
        margin-bottom: 20px;
        ul {
            position: relative;
            top: 0;
            left: 0;
            margin-top: 15px;
            margin-bottom: 15px;
            li {
                color: #999999;
                &:after {
                    color: #999;
                    margin-right: 8px;
                    margin-left: 8px;
                }
            }
        }
        > img {
            display: block;
            position: relative;
            height: auto;
        }
        h1 {
            font-size: 28px;
            color: #1c3144;
            margin-bottom:0;
        }
        &.second {
            padding-top: 15px;
            margin-bottom: 20px;
            h1 {
                font-size: 28px;
                margin-top: 20px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
        &.second {
            h1 {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        h1 {
            font-size: 22px;
        }
        &.second {
            h1 {
                font-size: 22px;
            }
        }
    }
}
/* breadcrumbs */

/* page about */
.m-t {
    margin-top: 80px;
    @media screen and (max-width: 1440px) {
        margin-top: 60px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 50px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 35px;
    }
}
.page {
    .cat__block {
        .cat__item {
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        .cat__block {
            .cat__item {
                margin-bottom: 20px;
            }
        }
    }
}
.pagination {
    line-height: 1;
    margin-top: 15px;
    ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            margin: 2.5px;
            a {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $boldS;
                font-size: 18px;
                text-align: center;
                color: #1c3144;
                border-radius: 3px;
                &:hover {
                    background-color: #eef8ff;
                    color: #1099a1;
                }
            }
            &.active a {
                background-color: #1099a1;
                color: #fff;
            }
            &.prev {
                a {
                    &:after {
                        content: '\f100';
                        font-family: 'fontAwesome';
                    }
                }
            }
            &.next {
                a {
                    &:after {
                        content: '\f101';
                        font-family: 'fontAwesome';
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        ul li {
            a {
                width: 36px;
                height: 36px;
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ul li {
            a {
                width: 30px;
                height: 30px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 10px;
    }
}
/* page about */

/* page catalog */
.cat--unit {
    display: flex;
    background-color: #eef8ff;
    margin-bottom: 170px;
    .col {
        width: 50%;
        position: relative;
    }
    .cat--unit__block {
        width: 570px;
        padding: 40px 0 40px 40px;
    }
    .price {
        display: block;
        font-family: $bold;
        font-size: 24px;
        color: #1c3144;
        margin: 35px 0 20px;
    }
    .btn {
        margin-top: 25px;
    }

    @media screen and (max-width: 1200px) {
        .cat--unit__block {
            width: 100%;
            padding: 30px;
        }
        .price {
            font-size: 22px;
            margin: 30px 0 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 120px;
        .price {
            font-size: 20px;
            margin: 20px 0 10px;
        }
        .btn {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 20px 15px;
        margin-bottom: 40px;
        .col {
            width: 100%;
        }
        .cat--unit__block {
            padding: 0;
        }
        .price {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 600px) {
        margin-bottom: 30px;
    }
}
.slider--for {
    height: 100%;
    .slick-list, .slick-track {
        height: 100%;
    }
    .slick-slide {
        outline: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            border-radius: 5px;
        }
    }
}
.dots {
    .slick-dots {
        bottom: -40px;
        li {
            width: auto;
            height: auto;
            margin: 0 7.5px;
            button {
                width: 24px;
                height: 24px;
                padding: 0;
                border-radius: 100%;
                border: 1px solid transparent;
                transition: 300ms;
                &:before {
                    display: none;
                }
                &:after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-color: #ffba08;
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    transform: translateY(-50%);
                    transition: 300ms;
                }
                &:hover {
                    border: 1px solid #1099a1;
                    &:after {
                        background-color: #1099a1;
                    }
                }
            }
            &.slick-active {
                button {
                    border: 1px solid #1099a1;
                    &:after {
                        background-color: #1099a1;
                    }
                }
            }
        }
    }
}
.slider--nav {
    width: 575px;
    position: absolute;
    bottom: -80px;
    right: 20px;
    margin: 0!important;
    .slick-slide {
        height: 100px;
        outline: none;
        margin: 0 10px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            opacity: 0;
            transition: 300ms;
            pointer-events: none;
            z-index: 1;
        }
        &.slick-current:after {
            opacity: 0.7;
        }
        &:hover:after {
            background-color: #1099a1;
            opacity: 0.7;
        }
    }
    &.arrow {
        .slick-arrow {
            top: auto;
            transform: translateY(0);
            bottom: -40px;
            &:after {
                background: url(../img/next1.png) no-repeat center center;
                background-size: contain;
            }
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        right: 0;
        .slick-slide {
            margin: 0 7.5px;
        }
        &.arrow {
            .slick-arrow {
                bottom: -36px;
                &.slick-prev {
                    left: 7.5px;
                }
                &.slick-next {
                    right: 7.5px;
                }
            }
        }
        .slick-dots {
            li {
                margin: 0 5px;
                button {
                    width: 22px;
                    height: 22px;
                    &:after {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        bottom: -50px;
        .slick-dots {
            display: none!important;
        }
        .slick-slide {
            height: 85px;
        }
        &.arrow {
            .slick-arrow {
                bottom: -30px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0 50px;
        bottom: 0;
        position: relative;
        margin: 15px 0!important;
        &.arrow {
            .slick-arrow {
                width: 30px;
                bottom: 50%;
                transform: translateY(50%);
            }
        }
    }
    @media screen and (max-width: 600px) {
        padding: 0 40px;
        .slick-slide {
            height: 75px;
        }
        &.arrow {
            .slick-arrow {
                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding: 0 40px;
        .slick-slide {
            height: 60px;
        }
    }
    @media screen and (max-width: 380px) {
        padding: 0 30px;
    }
}
.cat--txt {
    h3 {
        margin: 35px 0 20px;
    }
}
/* page catalog */

/* page contacts */
.cts {
    display: flex;
    margin-top: -80px;
    .col {
        display: flex;
        padding: 80px 0;
        &.left {
            width: calc(50% - 80px);
            padding-right: 30px;
            justify-content: flex-end;
        }
        &.right {
            width: calc(50% + 80px);
            padding-left: 80px;
        }
    }
    .cts__block {
        width: 460px;
        &.l {
            width: 570px;
        }
    }
    textarea {
        height: 120px;
        resize: none;
    }
    .g-recaptcha {
        margin-bottom: 20px;
    }
    .sm {
        font-size: 14px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 20px;
    }
    h3 {
        margin: 0 0 30px;
    }
    .footer__soc li {
        margin-right: 20px;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        a {
            color: #40adb4;
            border: 1px solid #40adb4;
        }
    }
    @media screen and (max-width: 1440px) {
        margin-top: -50px;
        .col {
            padding: 50px 0;
        }
        h3 {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 1200px) {
        .col {
            padding: 50px 30px!important;
            &.left {
                width: 50%;
            }
            &.right {
                width: 50%;
            }
        }
        .cts__block {
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0;
        .col {
            padding: 20px 15px 30px!important;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        h3 {
            margin-bottom: 15px;
        }
        .col {
            width: 100%!important;
            &.left {
                padding-top: 5px!important;
            }
        }
        .cts__block {
            &.l {
                width: 100%;
                text-align: center;
                h3 {
                    margin-top: 5px;
                    margin-bottom: 10px;
                }
            }
        }
        .g-recaptcha {
            display: table;
            margin: 0 auto 10px;
        }
        .btn {
            margin: 0 auto;
        }
        .footer__soc li {
            margin-right: 15px;
        }
    }
    @media screen and (max-width: 340px) {
        .g-recaptcha {
            display: block;
            width: 280px;
            > div {
                transform: scale(0.915);
                transform-origin: 0 0;
            }
        }
    }
}
.cts__list {
    @include ul-default;
    font-family: $boldS;
    font-size: 18px;
    margin-bottom: 50px;
    li {
        margin: 25px 0;
    }
    .col--vi:after {
        background: url(../img/viber1.svg) no-repeat center center;
        background-size: contain;
    }
    .col--tg:after {
        background: url(../img/telegram1.svg) no-repeat center center;
        background-size: contain;
    }
    .col--wh:after {
        color: #7aef8f;
    }
    .col--phone:after {
        color: #1099a1;
        top: 3px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 30px;
        li {
            margin: 15px 0;
        }
        .col--phone:after {
            top: 2px;
        }
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 800px;
        padding: 85px 125px;
        font-size: 18px;
        background-color: #e9f4fb;
        color: #666666;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h3 {
            font-size: 30px;
            text-align: center;
            padding: 0 10px;
            margin: 0 0 35px;
        }
        h4 {
            margin: 10px 0;
        }
        > p {
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 30px;
        }
        &.modal__block2 {
            form {
                .g-recaptcha {
                    float: left;
                }
                .btn {
                    float: right;
                    margin: 0px 0 0 0;
                }
            }
        }
           
    }
    form {
        display: block;
        text-align: left;;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 50px;
            background-color: #fff;
            border-radius: 0px;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            color: #333333;
            border: 1px solid #dddddd;
            border-radius: 3px;
            padding: 15px 30px;
            margin-bottom: 20px;
            box-shadow: none;
            line-height: 1;
            &::placeholder {
                color: #999999;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            margin: 20px auto 0;
        }
        .down {
            display: flex;
            font-size: 14px;
            color: #666666;
            align-items: center;
            p {
                margin: 0;
                a {
                    color: #ffba08;
                    text-decoration: underline;
                }
            }
            .right {
                padding-left: 10px;
            }
        }
    }
    &#modal--finish {
        .modal__block {
            width: 560px;
            padding: 60px 80px;
        }
    }
    @media screen and (max-width: 1440px) {
        .modal__block {
            padding: 75px 100px;
            h3 {
                font-size: 26px;
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .modal__block {
            padding: 50px 80px;
            .close {
                right: 30px;
                top: 30px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            font-size: 16px;
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 15px 25px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .modal__block {
            width: 600px;
            font-size: 14px;
            padding: 50px;
            .close {
                right: 20px;
                top: 20px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            width: auto;
            left: 20px;
            right: 20px;
            padding: 30px;
            h3 {
                font-size: 22px;
                margin-bottom: 15px;
            }
            h4 {
                font-size: 16px;
            }
            .close {
                top: 15px;
                right: 15px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .modal__block {
            h3 {
                font-size: 20px;
            }
            > p {
                margin: 0;
            }
            .close {
                top: 10px;
                right: 10px;
            }
        }
        form {
            .down {
                flex-direction: column;
                text-align: center;
                .col {
                    width: 100%;
                }
                .right {
                    padding-left: 0;
                }
            }
            .g-recaptcha {
                display: table;
                float: none;
                margin: 0 auto 15px;
            }
        }
        &#modal--finish {
            .modal__block {
                width: auto;
                left: 20px;
                right: 20px;
                padding: 40px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
            padding: 20px;
            h3 {
                font-size: 18px;
                margin-bottom: 10px;
            }
            &.modal__block2 {
                form {
                    .g-recaptcha {
                        float: none;
                    }
                    .btn {
                        float: none;
                        margin: 0 auto;
                    }
                }
            }
        }
        &#modal--finish {
            .modal__block {
                padding: 20px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                display: block;
                width: 260px;
                margin-bottom: 0;
                > div {
                    transform: scale(0.85);
                    transform-origin: 0 0;
                }
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 40px;
    display: table;
    background-color: #ffcc00;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #ffcc00;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #d9af04;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */
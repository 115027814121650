/* footer */
.footer__soc {
    display: flex;
    @include ul-default;
    li {
        margin: 15px 0 15px 15px;
        a {
            width: 40px;
            height: 40px;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border: 1px solid #b2cde1;
            font-size: 18px;
            overflow: hidden;
            &:hover {
                color: #fff;
                border: 1px solid transparent;
                .soc-bg {
                    opacity: 1;
                }
            }
            i {
                z-index: 1;
            }
            .fa-vk {
                font-size: 18px;
            }
        }
    }
}
.soc-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 300ms;
    opacity: 0;
    &.inst-bg {
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    }
    &.f-bg {
        background-color: #37aee2;
    }
    &.vk-bg {
        background-color: #4f7db3;
    }
}
footer {
    background: url(../img/img-24.jpg) no-repeat center center;
    background-size: cover;
    position: relative;
    font-size: 16px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #1c3144;
        opacity: 0.2;
    }
    > * {
        position: relative;
        z-index: 1;
    }
    ul {
        @include ul-default;
    }
    a {
        color: #fff;
        &:focus {
            color: #fff;
        }
    }
    h3, h4 {
        font-size: 16px;
        margin: 0;
        line-height: 1;
    }
    .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .up {
        border-bottom: 2px solid rgba(255,255,255,0.3);
        margin-bottom: 60px;
        .container {
            justify-content: space-between;
        }
    }
    .footer__nav {
        display: flex;
        li {
            margin-right: 45px;
            a {
                font-family: $boldS;
                text-transform: uppercase;
            }
            &.active a,
            &:hover a {
                color: #ffba08;
            }
        }
    }
    h2 {
        font-family: $boldS;
        font-size: 24px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin:  0 0 35px;
    }
    .footer__item {
        display: flex;
        width: 100%;
        font-family: $boldS;
        font-size: 18px;
        justify-content: space-between;
        ul {
            li {
                margin: 30px 0;
            }
        }
        .btn {
            margin-top: 0px;
        }
    }
    .f-cts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .col--email,
    .col--phone,
    .col--wh {
        &:after {
            color: #b5e5ef;
        }
    }
    .col--phone:after {
        top: 2px;
    }
    .col--email {
        padding-right: 15px;
        a {
            font-size: 16px;
            font-family: $regular;
        }
    }
    h5, h6 {
        font-family: $regular;
        font-size: 14px;
        color: #c6cace;
        margin: 0;
        text-transform: initial;
    }
    .artmedia {
        a {
            display: flex;
            align-items: center;
            color: #c6cace;
        }
    }
    .down {
        background-color: rgba(28, 49, 68, 0.3);
        margin-top: 80px;
        .container {
            height: 60px;
            justify-content: space-between;
        }
    }
    @media screen and (max-width: 1024px) {
        .footer__nav {
            li {
                margin-right: 30px;
            }
        }
        h2 {
            font-size: 20px;
            margin-bottom: 15px;
        }
        .up {
            margin-bottom: 40px;
        }
        .footer__item {
            ul {
                li {
                    margin: 20px 0;
                }
            }
        }
        .down {
            margin-top: 40px;
        }
    }
    @media screen and (max-width: 768px) {
        .up {
            margin-bottom: 20px;
            .container {
                padding: 15px 15px 10px;
                justify-content: center;
            }
        }
        .down {
            margin-top: 20px;
        }
        h2 {
            font-size: 18px;
            margin-bottom: 10px;
        }
        .footer__item {
            font-size: 16px;
        }
        .footer__nav {
            width: 100%;
            justify-content: center;
            li {
                margin: 0 15px;
            }
        }
        .footer__soc {
            li {
                margin: 10px 10px;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .down {
            .container {
                height: auto;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 15px;
            }
        }
        .artmedia {
            margin-top: 5px;
        }
    }
    @media screen and (max-width: 600px) {
        .footer__nav {
            display: none;
        }
        h2 {
            margin-bottom: 15px;
        }
        .footer__item {
            flex-direction: column;
            ul {
                li {
                    margin: 0 0 15px;
                }
            }
        }
        .f-item {
            width: 100%;
        }
        .f-cts {
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}
/* footer */
/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'GilroyH';
    src: url('../fonts/Gilroy-Heavy.eot');
    src: local('../fonts/Gilroy Heavy'), local('Gilroy-Heavy'),
        url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Heavy.woff') format('woff'),
        url('../fonts/Gilroy-Heavy.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroySB';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: local('../fonts/Gilroy SemiBold'), local('Gilroy-SemiBold'),
        url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff'),
        url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroyL';
    src: url('../fonts/Gilroy-Light.eot');
    src: local('../fonts/Gilroy Light'), local('Gilroy-Light'),
        url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Light.woff') format('woff'),
        url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroyR';
    src: url('../fonts/Gilroy-Regular.eot');
    src: local('../fonts/Gilroy Regular'), local('Gilroy-Regular'),
        url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroyM';
    src: url('../fonts/Gilroy-Medium.eot');
    src: local('../fonts/Gilroy Medium'), local('Gilroy-Medium'),
        url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Medium.woff') format('woff'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroyB';
    src: url('../fonts/Gilroy-Bold.eot');
    src: local('../fonts/Gilroy Bold'), local('Gilroy-Bold'),
        url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'GilroyBlack';
    src: url('../fonts/Gilroy-Black.eot');
    src: local('../fonts/Gilroy Black'), local('Gilroy-Black'),
        url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Black.woff') format('woff'),
        url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-style: normal;
}

$regular: 'GilroyR';
$bold: 'GilroyB';
$boldS: 'GilroySB';
$light: 'GilroyL';
$heavy: 'GilroyH';
$black: 'GilroyBlack';
$medium: 'GilroyM';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

[data-src] {
	opacity: 0;
	&[src] {
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 0.2s;
	}
}

.preload {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	pointer-events: none;
	&.load {
		opacity: 0;
		transition: 500ms;
	}
	&.loadR {
		opacity: 1;
		transition: 500ms;
	}
	// .sk-double-bounce {
	// 	width: 80px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	right: 0;
	// 	transform: translateY(-50%);
	// 	margin: auto;
	// 	.sk-child {
	// 	    width: 100%;
	// 	    height: 100%;
	// 	    border-radius: 50%;
	// 	    background-color: #099;
	// 	    opacity: 0.6;
	// 	    position: absolute;
	// 	    top: 0;
	// 	    left: 0;
	// 	    animation: sk-double-bounce 2.0s infinite ease-in-out;
	// 	}
	//   	.sk-double-bounce-2 {
	//     	animation-delay: -1.0s;
	//   	}
	// }
}
@keyframes sk-double-bounce {
    0%, 100% {
    	transform: scale(0);
  	}
  	50% {
    	transform: scale(1.0);
  	}
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	margin: 0;
    @media screen and (max-width: 1024px) {
        padding-top: 80px;
    }
}

h1 {
	font-family: $bold;
	font-size: 42px;
	font-weight: normal;
	color: #1c3144;
	line-height: 1;
	margin: 0 0 30px;
    a {
        font-size: 42px;
	}
	@media screen and (max-width: 1440px) {
		font-size: 38px;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 32px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
		font-size: 24px;
		margin-bottom: 15px;
	}
	@media screen and (max-width: 600px) {
		font-size: 22px;
	}
}
h2 {
	font-family: $bold;
	font-size: 36px;
	font-weight: normal;
	color: #1c3144;
	line-height: 1;
    margin: 0 0 35px;
	@media screen and (max-width: 1440px) {
		font-size: 32px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
		margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 15px;
	}
	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
}
h3 {
	font-family: $bold;
	font-size: 24px;
	font-weight: normal;
	color: #1c3144;
	line-height: 1.2;
	@media screen and (max-width: 1440px) {
		font-size: 22px;
	}
    @media screen and (max-width: 1024px) {
        font-size: 20px;
	}
	@media screen and (max-width: 768px) {
		font-size: 18px;
	}
}
h4 {
	font-family: $bold;
	font-size: 22px;
	font-weight: normal;
	color: #1c3144;
    @media screen and (max-width: 1440px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	font-weight: normal;
	color: #1c3144;
	@media screen and (max-width: 1024px) {
		font-size: 16px;
	}
}
h6 {
	font-family: $bold;
	font-size: 16px;
	font-weight: normal;
	text-transform: uppercase;
	color: #1c3144;
}
p {
    margin: 0 0 15px;
}
a {
    color: #333;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #333;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #ffba08;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.wrapper, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 16px;
	line-height: 1.4;
	textarea, 
	input[type="text"], 
	input[type="email"], 
	input[type="search"], 
	input[type="password"] {
		width: 100%;
		min-height: 50px;
		background-color: #fff;
		border-radius: 0px;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		color: #333333;
		border: 1px solid #dddddd;
		border-radius: 3px;
		padding: 15px 30px;
		margin-bottom: 20px;
		line-height: 1;
		&::placeholder {
			color: #999999;
		}
	}
	@media screen and (max-width: 1024px) {
		line-height: 1.2;
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			min-height: 40px;
			font-size: 14px;
			padding: 10px 15px;
			margin-bottom: 15px;
		}
	}
}
.wrapper {
	.container {
		padding-top: 85px;
		padding-bottom: 85px;
		@media screen and (max-width: 1440px) {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	h2 {
		text-align: center;
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 40px;
    top: 40px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 30px;
        height: 2px;
        background-color: #1099a1;
        position: absolute;
        top: 50%;
        left: -3px;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
	}
	@media screen and (max-width: 1024px) {
		width: 20px;
		height: 20px;
		&:before, &:after {
			width: 100%;
			left: 0;
		}
	}
	@media screen and (max-width: 768px) {
		width: 18px;
		height: 18px;
	}
}
.btn {
	display: table;
	height: 52px;
	width: auto!important;
	background-color: transparent!important;
	font-family: $boldS!important;
	font-size: 16px;
	background-color: #ffba08!important;
	color: #fff!important;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	border: 1px solid #ffba08;
	padding: 17px 40px 14px!important;
	border-radius: 3px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #ffba08!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #52b5bd!important;
		color: #fff!important;
		border: 1px solid #52b5bd;
	}	
	@media screen and (max-width: 1200px) {
		height: 42px;
		font-size: 14px;
		padding: 13px 30px!important;
	}
	@media screen and (max-width: 1024px) {
		height: 40px;
		padding: 12px 25px!important;
	}
}

.btn.tr {
	border: 1px solid #52b5bd!important;
	background-color: transparent!important;
	color: #1099a1!important;
	&:hover {
		background-color: #ffba08!important;
		border: 1px solid #ffba08!important;
		color: #fff!important;
	}
}

.btn.wh {
	border: 1px solid #fff!important;
	background-color: transparent!important;
	color: #fff!important;
	&:hover {
		background-color: #ffba08!important;
		border: 1px solid #ffba08!important;
		color: #fff!important;
	}
}

.bg-blue {
	background-color: #eef8ff;
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 23px;
		margin: 8px 0;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 13px;
			color: #1099a1;
			left: 0;
			top: 4px;
		}
	}
	&.yellow {
		li {
			&:before {
				color: #ffba08;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		li {
			margin: 5px 0;
			&:before {
				top: 2px;
			}
		}
	}
}

.col--location,
.col--phone,
.col--email,
.col--wh,
.col--vi, 
.col--tg {
	position: relative;
	padding-left: 28px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #ffba08;
		position: absolute;
		top: 0px;
		left: 0;
	}
	@media screen and (max-width: 1024px) {
		padding-left: 25px;
		&:after {
			font-size: 18px;
		}
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email {
	&:after {
		content: '\f0e0';
		font-size: 17px;
		top: 2px;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	@media screen and (max-width: 768px) {
		&:after {
			font-size: 15px;
		}
	}
}
.col--wh:after {
	content: '\f232';
	font-size: 22px;
	@media screen and (max-width: 1024px) {
		font-size: 20px;
	}
}
.col--vi:after,
.col--tg:after {
	content: '';
	width: 20px;
	height: 20px;
	opacity: 0.7;
	@media screen and (max-width: 1024px) {
		width: 18px;
		height: 18px;
	}
}
.col--vi:after {
	background: url(../img/viber.svg) no-repeat center center;
	background-size: contain;
}
.col--tg:after {
	background: url(../img/telegram.svg) no-repeat center center;
	background-size: contain;
}

.green {
	display: block;
	color: #1099a1;
}

.bg {
	position: relative;
	color: #fff;
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	> img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
}
.txt-m {
	font-size: 18px;
	@media screen and (max-width: 1024px) {
		font-size: 16px;
	}
}
.center {
	text-align: center;
}
.inst--title {
	display: table;
	position: relative;
	padding-left: 75px;
	margin-left: auto;
	margin-right: auto;
	img {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	a {
		color: #1099a1;
		&:hover {
			color: #ffba08;
		}
	}
	@media screen and (max-width: 1024px) {
		padding-left: 50px;
		img {
			width: 38px;
		}
	}
	@media screen and (max-width: 768px) {
		padding-left: 35px;
		img {
			width: 28px;
		}
	}
	@media screen and (max-width: 420px) {
		font-size: 18px;
	}
	@media screen and (max-width: 360px) {
		font-size: 16px;
		padding-left: 30px;
		img {
			width: 24px;
		}
	}
}

/* header */
header {
	font-family: $boldS;
	font-size: 16px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	border-bottom: 2px solid rgba(255,255,255,0.3);
	.container {
		height: 125px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
	}
	ul {
		@include ul-default;
	}
	a {
		color: #fff;
		&:focus {
			color: #fff;
		}
	}


	/* header logo */
	.logo, .logo1 {
		position: absolute;
		top: 50%;
		left: 15px;
		transform: translateY(-50%);
	}
	.logo1 {
		opacity: 0;
	}
	/* header logo */

	/* header nav */
	.nav {
		margin-left: 265px;
		> ul {
			display: flex;
			> li {
				margin-right: 34px;
				> a {
					text-transform: uppercase;
					&:hover {
						color: #ffba08;
					}
				}
				&.active > a {
					color: #ffba08;
				}
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		text-transform: uppercase;
		position: relative;
		padding-right: 14px;
		position: absolute;
		right: 285px;
		top: 50%;
		transform: translateY(-50%);
		.langs__title {
			color: #fff;
		}
		.arr {
			font-family: 'fontAwesome';
			position: absolute;
			top: 45%;
			transform: translateY(-50%);
			right: 0;
			color: #fff;
			&:after {
				content: '\f107';
			}
			&.open {
				transform: rotate(-180deg) translateY(50%);
			}
			&:hover {
				color: #ffba08;
			}
		}
		ul {
			display: none;
			background-color: #1099a1;
			position: absolute;
			left: -9px;
			top: calc(100% + 5px);
			li {
				a {
					display: block;
					padding: 4px 10px;
					&:hover {
						background-color: #ffba08;
						color: #fff;
					}
				}
				&.active > a {
					background-color: #ffba08;
					color: #fff;
				}
				&:first-of-type {
					> a {
						padding-top: 6px;
					}
				}
				&:last-of-type {
					> a {
						padding-bottom: 6px;
					}
				}
			}
		}
	}
	/* header langs */

	/* header phone */
	.phone {
		font-size: 24px;
		font-weight: normal;
		line-height: 1;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		.btn-h {
			font-size: 14px;
			color: #ffba08;
			text-transform: uppercase;
			text-decoration: underline;
			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
		ul {
			li {
				margin-bottom: 3px;
			}
		}
		.close {
			display: none;
		}
	}
	.col--phone {
		padding-left: 22px;
		&:after {
			top: 3px;
		}
	}
	/* header phone */
	@media screen and (min-width: 1025px) {
		transition: 300ms;
		.langs {
			.langs__title {
				transition: 300ms;
			}
		}
		&.fix, &.header1 {
			background-color: #fff;
			border-bottom: 2px solid #eef8ff;
			box-shadow: 0px 0px 15px #eef8ff;
			.logo {
				opacity: 0;
			}
			.logo1 {
				opacity: 1;
			}
			.nav > ul > li {
				> a {
					color: #333333;
					&:hover {
						color: #ffba08;
					}
				}
				&.active > a {
					color: #ffba08;
				}
			}
			.langs .langs__title {
				color: #333;
			}
			.arr {
				color: #333;
			}
			.phone {
				ul a {
					color: #1c3144;
				}
			}
			.phone .btn-h {
				&:hover {
					color: #ffba08;
				}
			}
		}
	} 

	@media screen and (max-width: 1440px) {
		.container {
			height: 100px;
		}
		.phone {
			font-size: 22px;
		}
		.col--phone:after {
			top: 1px;
		}
	}
	
	@media screen and (max-width: 1200px) {
		.phone {
			font-size: 20px;
		}
		.langs {
			right: 210px;
		}
	}

	@media screen and (max-width: 1024px) {
		background-color: #fff;
		border-bottom: 1px solid #469ed7;
		box-shadow: 0px 0px 15px #469ed7;
		a {
			color: #333;
		}
		.container {
			height: 80px;
		}
		.logo {
			display: none;
		}
		.logo1 {
			width: 180px;
			opacity: 1;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
		.phone {
			font-size: 18px;
			position: absolute;
			left: auto;
			right: 15px;
			.btn-h {
				display: none;
			}
		}
		.col--phone:after {
			font-size: 18px;
		}
		.nav {
			display: none;
			max-height: calc(100% - 80px);
			background-color: #fff;
			position: fixed;
			top: 80px;
			left: 0;
			right: 0;
			z-index: 1;
			padding: 10px 15px;
			margin: 0;
			overflow: auto;
			border-top: 1px solid #469ed7;
			border-bottom: 1px solid #469ed7;
			> ul {
				flex-direction: column;
				> li {
					margin: 8px 0;
					> {
						a {
							font-size: 16px;
							color: #333;
						}
					}
				}
			}
		}
		.langs {
			right: auto;
			left: 60px;
			.langs__title {
				color: #333;
			}
			.arr {
				color: #333;
			}
			ul {
				li {
					a {
						color: #fff;
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #469ed7;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.phone {
			display: none;
			background-color: #469ed7;
			top: 0;
			left: 0;
			right: 0;
			transform: translateY(0);
			z-index: 1;
			padding: 10px 15px;
			text-align: center;
			line-height: 1;
			&:after {
				display: none;
			}
			li {
				margin: 5px 0;
				a {
					font-size: 16px;
					color: #fff;
				}
			}
			.close {
				display: block;
				width: 18px;
				height: 18px;
				top: 5px;
				right: 5px;
				&:after, &:before {
					width: 100%;
					background-color: #fff;
					left: 0;
				}
			}
		}
		.phone--btn {
			font-family: 'fontAwesome';
			font-size: 22px;
			color: #ffba08;
			text-decoration: none!important;
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			&:after {
				content: '\f095';
			}
		}
	}
	@media screen and (max-width: 480px) {
		.nav--btn {
			left: 10px;
			top: 55%;
		}
		.langs {
			font-size: 14px;
			left: 12px;
			top: 10px;
			transform: translateY(0);
			z-index: 2;
			line-height: 1;
		}
		.phone {
			z-index: 3;
		}
		.logo1 {
			width: 160px;
		}
	}
}
/* header */
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
  font-family: 'GilroyH';
  src: url("../fonts/Gilroy-Heavy.eot");
  src: local("../fonts/Gilroy Heavy"), local("Gilroy-Heavy"), url("../fonts/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Heavy.woff") format("woff"), url("../fonts/Gilroy-Heavy.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroySB';
  src: url("../fonts/Gilroy-SemiBold.eot");
  src: local("../fonts/Gilroy SemiBold"), local("Gilroy-SemiBold"), url("../fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-SemiBold.woff") format("woff"), url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroyL';
  src: url("../fonts/Gilroy-Light.eot");
  src: local("../fonts/Gilroy Light"), local("Gilroy-Light"), url("../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy-Light.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroyR';
  src: url("../fonts/Gilroy-Regular.eot");
  src: local("../fonts/Gilroy Regular"), local("Gilroy-Regular"), url("../fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroyM';
  src: url("../fonts/Gilroy-Medium.eot");
  src: local("../fonts/Gilroy Medium"), local("Gilroy-Medium"), url("../fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Medium.woff") format("woff"), url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroyB';
  src: url("../fonts/Gilroy-Bold.eot");
  src: local("../fonts/Gilroy Bold"), local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'GilroyBlack';
  src: url("../fonts/Gilroy-Black.eot");
  src: local("../fonts/Gilroy Black"), local("Gilroy-Black"), url("../fonts/Gilroy-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Black.woff") format("woff"), url("../fonts/Gilroy-Black.ttf") format("truetype");
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "GilroyR";
  -webkit-text-size-adjust: 100%;
  margin: 0; }
  @media screen and (max-width: 1024px) {
    body {
      padding-top: 80px; } }

h1 {
  font-family: "GilroyB";
  font-size: 42px;
  font-weight: normal;
  color: #1c3144;
  line-height: 1;
  margin: 0 0 30px; }
  h1 a {
    font-size: 42px; }
  @media screen and (max-width: 1440px) {
    h1 {
      font-size: 38px;
      margin-bottom: 25px; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-family: "GilroyB";
  font-size: 36px;
  font-weight: normal;
  color: #1c3144;
  line-height: 1;
  margin: 0 0 35px; }
  @media screen and (max-width: 1440px) {
    h2 {
      font-size: 32px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-family: "GilroyB";
  font-size: 24px;
  font-weight: normal;
  color: #1c3144;
  line-height: 1.2; }
  @media screen and (max-width: 1440px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 18px; } }

h4 {
  font-family: "GilroyB";
  font-size: 22px;
  font-weight: normal;
  color: #1c3144; }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "GilroyB";
  font-size: 18px;
  font-weight: normal;
  color: #1c3144; }
  @media screen and (max-width: 1024px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-family: "GilroyB";
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  color: #1c3144; }

p {
  margin: 0 0 15px; }

a {
  color: #333;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #333;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #ffba08; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.wrapper, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 16px;
  line-height: 1.4; }
  .wrapper textarea,
  .wrapper input[type="text"],
  .wrapper input[type="email"],
  .wrapper input[type="search"],
  .wrapper input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    border-radius: 0px;
    background-color: #fff;
    font-family: "GilroyR";
    font-size: 16px;
    color: #333333;
    border: 1px solid #dddddd;
    border-radius: 3px;
    padding: 15px 30px;
    margin-bottom: 20px;
    line-height: 1; }
    .wrapper textarea::placeholder,
    .wrapper input[type="text"]::placeholder,
    .wrapper input[type="email"]::placeholder,
    .wrapper input[type="search"]::placeholder,
    .wrapper input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #999999; }
  @media screen and (max-width: 1024px) {
    .wrapper, .page {
      line-height: 1.2; }
      .wrapper textarea,
      .wrapper input[type="text"],
      .wrapper input[type="email"],
      .wrapper input[type="search"],
      .wrapper input[type="password"], .page textarea,
      .page input[type="text"],
      .page input[type="email"],
      .page input[type="search"],
      .page input[type="password"] {
        min-height: 40px;
        font-size: 14px;
        padding: 10px 15px;
        margin-bottom: 15px; } }

.wrapper .container {
  padding-top: 85px;
  padding-bottom: 85px; }
  @media screen and (max-width: 1440px) {
    .wrapper .container {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 1024px) {
    .wrapper .container {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 768px) {
    .wrapper .container {
      padding-top: 30px;
      padding-bottom: 30px; } }

.wrapper h2 {
  text-align: center; }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%; } }

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 40px;
  top: 40px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 30px;
    height: 2px;
    background-color: #1099a1;
    position: absolute;
    top: 50%;
    left: -3px;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }
  @media screen and (max-width: 1024px) {
    .close {
      width: 20px;
      height: 20px; }
      .close:before, .close:after {
        width: 100%;
        left: 0; } }
  @media screen and (max-width: 768px) {
    .close {
      width: 18px;
      height: 18px; } }

.btn {
  display: table;
  height: 52px;
  width: auto !important;
  background-color: transparent !important;
  font-family: "GilroySB" !important;
  font-size: 16px;
  background-color: #ffba08 !important;
  color: #fff !important;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #ffba08;
  padding: 17px 40px 14px !important;
  border-radius: 3px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #ffba08 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #52b5bd !important;
    color: #fff !important;
    border: 1px solid #52b5bd; }
  @media screen and (max-width: 1200px) {
    .btn {
      height: 42px;
      font-size: 14px;
      padding: 13px 30px !important; } }
  @media screen and (max-width: 1024px) {
    .btn {
      height: 40px;
      padding: 12px 25px !important; } }

.btn.tr {
  border: 1px solid #52b5bd !important;
  background-color: transparent !important;
  color: #1099a1 !important; }
  .btn.tr:hover {
    background-color: #ffba08 !important;
    border: 1px solid #ffba08 !important;
    color: #fff !important; }

.btn.wh {
  border: 1px solid #fff !important;
  background-color: transparent !important;
  color: #fff !important; }
  .btn.wh:hover {
    background-color: #ffba08 !important;
    border: 1px solid #ffba08 !important;
    color: #fff !important; }

.bg-blue {
  background-color: #eef8ff; }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    padding-left: 23px;
    margin: 8px 0; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 13px;
      color: #1099a1;
      left: 0;
      top: 4px; }
  .check--list.yellow li:before {
    color: #ffba08; }
  @media screen and (max-width: 1024px) {
    .check--list li {
      margin: 5px 0; }
      .check--list li:before {
        top: 2px; } }

.col--location,
.col--phone,
.col--email,
.col--wh,
.col--vi,
.col--tg {
  position: relative;
  padding-left: 28px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after,
  .col--wh:after,
  .col--vi:after,
  .col--tg:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #ffba08;
    position: absolute;
    top: 0px;
    left: 0; }
  @media screen and (max-width: 1024px) {
    .col--location,
    .col--phone,
    .col--email,
    .col--wh,
    .col--vi,
    .col--tg {
      padding-left: 25px; }
      .col--location:after,
      .col--phone:after,
      .col--email:after,
      .col--wh:after,
      .col--vi:after,
      .col--tg:after {
        font-size: 18px; } }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--email:after {
  content: '\f0e0';
  font-size: 17px;
  top: 2px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

@media screen and (max-width: 768px) {
  .col--email:after {
    font-size: 15px; } }

.col--wh:after {
  content: '\f232';
  font-size: 22px; }
  @media screen and (max-width: 1024px) {
    .col--wh:after {
      font-size: 20px; } }

.col--vi:after,
.col--tg:after {
  content: '';
  width: 20px;
  height: 20px;
  opacity: 0.7; }
  @media screen and (max-width: 1024px) {
    .col--vi:after,
    .col--tg:after {
      width: 18px;
      height: 18px; } }

.col--vi:after {
  background: url(../img/viber.svg) no-repeat center center;
  background-size: contain; }

.col--tg:after {
  background: url(../img/telegram.svg) no-repeat center center;
  background-size: contain; }

.green {
  display: block;
  color: #1099a1; }

.bg {
  position: relative;
  color: #fff; }
  .bg h1, .bg h2, .bg h3, .bg h4, .bg h5, .bg h6 {
    color: #fff; }
  .bg > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }

.txt-m {
  font-size: 18px; }
  @media screen and (max-width: 1024px) {
    .txt-m {
      font-size: 16px; } }

.center {
  text-align: center; }

.inst--title {
  display: table;
  position: relative;
  padding-left: 75px;
  margin-left: auto;
  margin-right: auto; }
  .inst--title img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .inst--title a {
    color: #1099a1; }
    .inst--title a:hover {
      color: #ffba08; }
  @media screen and (max-width: 1024px) {
    .inst--title {
      padding-left: 50px; }
      .inst--title img {
        width: 38px; } }
  @media screen and (max-width: 768px) {
    .inst--title {
      padding-left: 35px; }
      .inst--title img {
        width: 28px; } }
  @media screen and (max-width: 420px) {
    .inst--title {
      font-size: 18px; } }
  @media screen and (max-width: 360px) {
    .inst--title {
      font-size: 16px;
      padding-left: 30px; }
      .inst--title img {
        width: 24px; } }

/* header */
header {
  font-family: "GilroySB";
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */
  /* header langs */
  /* header langs */
  /* header phone */
  /* header phone */ }
  header .container {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header a {
    color: #fff; }
    header a:focus {
      color: #fff; }
  header .logo, header .logo1 {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%); }
  header .logo1 {
    opacity: 0; }
  header .nav {
    margin-left: 265px; }
    header .nav > ul {
      display: flex; }
      header .nav > ul > li {
        margin-right: 34px; }
        header .nav > ul > li > a {
          text-transform: uppercase; }
          header .nav > ul > li > a:hover {
            color: #ffba08; }
        header .nav > ul > li.active > a {
          color: #ffba08; }
  header .langs {
    text-transform: uppercase;
    position: relative;
    padding-right: 14px;
    position: absolute;
    right: 285px;
    top: 50%;
    transform: translateY(-50%); }
    header .langs .langs__title {
      color: #fff; }
    header .langs .arr {
      font-family: 'fontAwesome';
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      right: 0;
      color: #fff; }
      header .langs .arr:after {
        content: '\f107'; }
      header .langs .arr.open {
        transform: rotate(-180deg) translateY(50%); }
      header .langs .arr:hover {
        color: #ffba08; }
    header .langs ul {
      display: none;
      background-color: #1099a1;
      position: absolute;
      left: -9px;
      top: calc(100% + 5px); }
      header .langs ul li a {
        display: block;
        padding: 4px 10px; }
        header .langs ul li a:hover {
          background-color: #ffba08;
          color: #fff; }
      header .langs ul li.active > a {
        background-color: #ffba08;
        color: #fff; }
      header .langs ul li:first-of-type > a {
        padding-top: 6px; }
      header .langs ul li:last-of-type > a {
        padding-bottom: 6px; }
  header .phone {
    font-size: 24px;
    font-weight: normal;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%); }
    header .phone .btn-h {
      font-size: 14px;
      color: #ffba08;
      text-transform: uppercase;
      text-decoration: underline; }
      header .phone .btn-h:hover {
        color: #fff;
        text-decoration: none; }
    header .phone ul li {
      margin-bottom: 3px; }
    header .phone .close {
      display: none; }
  header .col--phone {
    padding-left: 22px; }
    header .col--phone:after {
      top: 3px; }
  @media screen and (min-width: 1025px) {
    header {
      transition: 300ms; }
      header .langs .langs__title {
        transition: 300ms; }
      header.fix, header.header1 {
        background-color: #fff;
        border-bottom: 2px solid #eef8ff;
        box-shadow: 0px 0px 15px #eef8ff; }
        header.fix .logo, header.header1 .logo {
          opacity: 0; }
        header.fix .logo1, header.header1 .logo1 {
          opacity: 1; }
        header.fix .nav > ul > li > a, header.header1 .nav > ul > li > a {
          color: #333333; }
          header.fix .nav > ul > li > a:hover, header.header1 .nav > ul > li > a:hover {
            color: #ffba08; }
        header.fix .nav > ul > li.active > a, header.header1 .nav > ul > li.active > a {
          color: #ffba08; }
        header.fix .langs .langs__title, header.header1 .langs .langs__title {
          color: #333; }
        header.fix .arr, header.header1 .arr {
          color: #333; }
        header.fix .phone ul a, header.header1 .phone ul a {
          color: #1c3144; }
        header.fix .phone .btn-h:hover, header.header1 .phone .btn-h:hover {
          color: #ffba08; } }
  @media screen and (max-width: 1440px) {
    header .container {
      height: 100px; }
    header .phone {
      font-size: 22px; }
    header .col--phone:after {
      top: 1px; } }
  @media screen and (max-width: 1200px) {
    header .phone {
      font-size: 20px; }
    header .langs {
      right: 210px; } }
  @media screen and (max-width: 1024px) {
    header {
      background-color: #fff;
      border-bottom: 1px solid #469ed7;
      box-shadow: 0px 0px 15px #469ed7; }
      header a {
        color: #333; }
      header .container {
        height: 80px; }
      header .logo {
        display: none; }
      header .logo1 {
        width: 180px;
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto; }
        header .logo1 img {
          width: 100%; }
      header .phone {
        font-size: 18px;
        position: absolute;
        left: auto;
        right: 15px; }
        header .phone .btn-h {
          display: none; }
      header .col--phone:after {
        font-size: 18px; }
      header .nav {
        display: none;
        max-height: calc(100% - 80px);
        background-color: #fff;
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 10px 15px;
        margin: 0;
        overflow: auto;
        border-top: 1px solid #469ed7;
        border-bottom: 1px solid #469ed7; }
        header .nav > ul {
          flex-direction: column; }
          header .nav > ul > li {
            margin: 8px 0; }
            header .nav > ul > li > a {
              font-size: 16px;
              color: #333; }
      header .langs {
        right: auto;
        left: 60px; }
        header .langs .langs__title {
          color: #333; }
        header .langs .arr {
          color: #333; }
        header .langs ul li a {
          color: #fff; }
      header .nav--btn {
        width: 35px;
        height: 30px;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: 0;
        outline: none;
        float: none; }
        header .nav--btn span {
          position: absolute;
          left: 0;
          right: 0;
          width: 85%;
          height: 3px;
          margin: 0 auto;
          background-color: #469ed7;
          transition: 350ms; }
          header .nav--btn span:nth-of-type(1) {
            top: 7px; }
          header .nav--btn span:nth-of-type(2) {
            top: 14px; }
          header .nav--btn span:nth-of-type(3) {
            top: 21px; }
        header .nav--btn.open span:nth-of-type(1) {
          top: 14px;
          transform: rotate(45deg); }
        header .nav--btn.open span:nth-of-type(2) {
          opacity: 0; }
        header .nav--btn.open span:nth-of-type(3) {
          top: 14px;
          transform: rotate(-45deg); } }
  @media screen and (max-width: 600px) {
    header .phone {
      display: none;
      background-color: #469ed7;
      top: 0;
      left: 0;
      right: 0;
      transform: translateY(0);
      z-index: 1;
      padding: 10px 15px;
      text-align: center;
      line-height: 1; }
      header .phone:after {
        display: none; }
      header .phone li {
        margin: 5px 0; }
        header .phone li a {
          font-size: 16px;
          color: #fff; }
      header .phone .close {
        display: block;
        width: 18px;
        height: 18px;
        top: 5px;
        right: 5px; }
        header .phone .close:after, header .phone .close:before {
          width: 100%;
          background-color: #fff;
          left: 0; }
    header .phone--btn {
      font-family: 'fontAwesome';
      font-size: 22px;
      color: #ffba08;
      text-decoration: none !important;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%); }
      header .phone--btn:after {
        content: '\f095'; } }
  @media screen and (max-width: 480px) {
    header .nav--btn {
      left: 10px;
      top: 55%; }
    header .langs {
      font-size: 14px;
      left: 12px;
      top: 10px;
      transform: translateY(0);
      z-index: 2;
      line-height: 1; }
    header .phone {
      z-index: 3; }
    header .logo1 {
      width: 160px; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    height: 100vh;
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2); }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider .slick-slide .slider__block {
      width: 1140px;
      font-size: 20px;
      font-family: "GilroyM";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      color: #fff;
      margin: 0 auto;
      transform: translateY(-50%);
      text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__title {
        display: block;
        font-family: "GilroyB";
        font-size: 48px;
        margin-bottom: 15px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 50px; }
  .slider .slick-dots {
    display: flex;
    width: auto;
    flex-direction: column;
    bottom: auto;
    top: 50%;
    left: auto;
    right: 50%;
    transform: translateY(-50%);
    margin-right: -570px; }
    .slider .slick-dots li {
      width: auto;
      height: auto;
      margin: 8px 0px; }
      .slider .slick-dots li button {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: 1px solid transparent;
        transition: 300ms;
        padding: 0; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:after {
          content: '';
          width: 12px;
          height: 12px;
          background-color: #fff;
          opacity: 0.7;
          border-radius: 100%;
          box-sizing: border-box;
          transition: 300ms;
          border: 3px solid transparent; }
        .slider .slick-dots li button:hover:after {
          background-color: transparent;
          border: 3px solid #fff;
          opacity: 1; }
      .slider .slick-dots li.slick-active button {
        border: 1px solid #fff; }
        .slider .slick-dots li.slick-active button:after {
          background-color: transparent;
          border: 3px solid #fff;
          opacity: 1; }
  @media screen and (max-width: 1440px) {
    .slider .slick-dots {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      top: auto;
      bottom: 28px;
      left: 0;
      right: 0;
      transform: translateY(0); }
    .slider .slick-dots li {
      margin: 0 8px; }
    .slider .slick-slide .slider__block .slider__title {
      font-size: 42px;
      line-height: 1; }
    .slider .slick-slide .slider__block .btn {
      margin-top: 30px; } }
  @media screen and (max-width: 1366px) {
    .slider .slick-dots {
      bottom: 20px; }
    .slider .slick-slide .slider__block {
      width: auto;
      left: 115px;
      right: 115px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 36px; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 28px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 120px; } }
  @media screen and (max-width: 1200px) {
    .slider .slick-slide .slider__block {
      font-size: 18px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 30px; } }
  @media screen and (max-width: 1024px) {
    .slider.arrow .slick-arrow {
      display: none !important;
      width: 35px; }
      .slider.arrow .slick-arrow.slick-next {
        right: 28px; }
    .slider .slick-slide {
      height: 375px; }
      .slider .slick-slide .slider__block {
        font-size: 16px;
        left: 15px;
        right: 15px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 24px; }
        .slider .slick-slide .slider__block .btn {
          margin-top: 20px; }
    .slider .slick-dots li {
      margin: 0 5px; }
      .slider .slick-dots li button {
        width: 20px;
        height: 20px; }
        .slider .slick-dots li button:after {
          width: 10px;
          height: 10px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide .slider__block .slider__title {
      font-size: 20px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide {
      height: 300px; }
      .slider .slick-slide .slider__block .slider__title {
        margin-bottom: 10px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 15px; }
    .slider .slick-dots {
      bottom: 15px; } }

.arrow .slick-arrow {
  width: 76px;
  height: 24px;
  z-index: 1; }
  .arrow .slick-arrow:before {
    display: none; }
  .arrow .slick-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../img/next.png) no-repeat center center;
    transition: 300ms; }
  .arrow .slick-arrow.slick-next {
    right: 120px; }
  .arrow .slick-arrow.slick-prev {
    left: 60px; }
    .arrow .slick-arrow.slick-prev:after {
      transform: rotate(-180deg); }
  .arrow .slick-arrow:hover:after {
    background: url(../img/next1.png) no-repeat center center; }

@media screen and (max-width: 1366px) {
  .arrow .slick-arrow {
    width: 50px;
    height: 20px; }
    .arrow .slick-arrow:after {
      background-size: contain; }
    .arrow .slick-arrow:hover:after {
      background-size: contain; } }

.f-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; }
  .f-btns a {
    display: flex;
    width: 72px;
    height: 52px;
    align-items: center;
    padding: 0 0 0 20px;
    border-radius: 3px 0 0 3px;
    margin: 5px 0; }
    .f-btns a img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      object-position: center center; }
    .f-btns a.wht {
      background-color: #42e960; }
    .f-btns a.vib {
      background-color: #7360f2; }
    .f-btns a.te {
      background-color: #37aee2; }
    .f-btns a:hover {
      width: 120px; }
  @media screen and (max-width: 1366px) {
    .f-btns a {
      height: 46px;
      width: 58px;
      padding: 0 0 0 15px; }
      .f-btns a img {
        width: 26px;
        height: 26px; }
      .f-btns a:hover {
        width: 80px; } }
  @media screen and (max-width: 1200px) {
    .f-btns a {
      height: 40px;
      width: 45px;
      padding-left: 10px; }
      .f-btns a img {
        width: 22px;
        height: 22px; }
      .f-btns a:hover {
        width: 60px; } }
  @media screen and (max-width: 1024px) {
    .f-btns a {
      width: 30px !important;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      padding: 0; }
      .f-btns a img {
        width: 18px;
        height: 18px; } }

.btns__block {
  display: flex;
  width: 1170px;
  position: absolute;
  top: calc(100vh - 140px);
  left: 0;
  right: 0;
  margin: 0 auto; }
  .btns__block a {
    display: flex;
    width: calc(33.33% - 30px);
    font-family: "GilroySB";
    color: #fff;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 11px 10px 9px;
    margin: 0 15px;
    border-radius: 3px; }
    .btns__block a img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      object-position: center center;
      margin-right: 10px; }
    .btns__block a.wht {
      background-color: #42e960; }
    .btns__block a.vib {
      background-color: #7360f2; }
    .btns__block a.te {
      background-color: #37aee2; }
    .btns__block a:hover {
      background-color: #ffba08; }
  @media screen and (max-width: 1366px) {
    .btns__block {
      width: auto;
      left: 105px;
      right: 105px;
      top: calc(100vh - 120px); }
      .btns__block a {
        width: calc(33.33% - 20px);
        font-size: 15px;
        margin: 0 10px; }
        .btns__block a img {
          width: 26px;
          height: 26px; } }
  @media screen and (max-width: 1200px) {
    .btns__block a {
      width: calc(33.33% - 15px);
      font-size: 14px;
      margin: 0 7.5px; }
      .btns__block a img {
        width: 22px;
        height: 22px;
        margin-right: 8px; } }
  @media screen and (max-width: 1024px) {
    .btns__block {
      display: none;
      left: 10px;
      right: 10px; } }

/* main slider */
/* main catalog */
.cat__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .cat__block .cat__item {
    display: flex;
    width: calc(50% - 15px);
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden; }
    .cat__block .cat__item .cat__img {
      overflow: hidden;
      height: 310px; }
      .cat__block .cat__item .cat__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
      .cat__block .cat__item .cat__img:hover img {
        transform: scale(1.1); }
    .cat__block .cat__item h3 {
      margin: 0 0 15px; }
    .cat__block .cat__item .cat__info {
      background-color: #eef8ff;
      padding: 30px 35px 100px;
      position: relative;
      flex: 1 0 auto; }
    .cat__block .cat__item .down {
      display: flex;
      align-items: center;
      position: absolute;
      left: 35px;
      right: 35px;
      bottom: 30px; }
      .cat__block .cat__item .down .btn {
        margin-right: 20px; }
  @media screen and (max-width: 1200px) {
    .cat__block .cat__item {
      width: calc(50% - 7.5px); }
      .cat__block .cat__item .cat__info {
        padding: 25px 30px 90px; }
      .cat__block .cat__item .down {
        left: 30px;
        right: 30px;
        bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    .cat__block .cat__item .cat__img {
      height: 260px; } }
  @media screen and (max-width: 768px) {
    .cat__block .cat__item .cat__img {
      height: 200px; }
    .cat__block .cat__item .cat__info {
      padding: 20px 20px 80px; }
    .cat__block .cat__item .down {
      left: 20px;
      bottom: 20px; }
      .cat__block .cat__item .down .btn {
        margin-right: 10px; } }
  @media screen and (max-width: 700px) {
    .cat__block .cat__item {
      width: 100%;
      margin-bottom: 15px; }
      .cat__block .cat__item:last-of-type {
        margin-bottom: 0; } }
  @media screen and (max-width: 360px) {
    .cat__block .cat__item .down .btn {
      padding-left: 20px !important;
      padding-right: 20px !important; } }

.more {
  display: inline-flex; }
  .more:after {
    content: '\f101';
    font-family: 'fontAwesome';
    font-size: 16px;
    margin: -2px 0 0 8px; }
  @media screen and (max-width: 768px) {
    .more:after {
      margin-left: 5px;
      margin-top: -3px; } }

/* main catalog */
/* main about */
.about {
  position: relative; }
  .about > img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -2; }
  @media screen and (max-width: 1024px) {
    .about .container {
      padding-top: 0; } }

.about__block {
  display: flex;
  align-items: flex-start; }
  .about__block .about__img {
    width: 550px;
    padding-right: 55px; }
    .about__block .about__img img {
      display: block;
      width: 100%; }
  .about__block .right {
    width: calc(100% - 500px); }
  .about__block h1 {
    margin-bottom: 35px; }
  .about__block .about__row {
    position: relative;
    padding-right: 40px; }
    .about__block .about__row.first {
      position: relative;
      margin-bottom: 110px; }
      .about__block .about__row.first:after {
        content: '';
        width: 100vw;
        background-color: #eef8ff;
        position: absolute;
        top: -90px;
        right: 50%;
        bottom: -55px;
        margin-right: -305px;
        z-index: -1; }
    .about__block .about__row h3 {
      margin-bottom: 40px; }
    .about__block .about__row h1 {
      margin-bottom: 30px; }
  @media screen and (max-width: 1440px) {
    .about__block .about__row.first {
      margin-bottom: 90px; }
      .about__block .about__row.first:after {
        top: -70px;
        bottom: -45px; }
    .about__block .about__img {
      padding-right: 40px; } }
  @media screen and (max-width: 1024px) {
    .about__block .about__row {
      padding-right: 0; }
      .about__block .about__row.first {
        margin-bottom: 40px; }
        .about__block .about__row.first:after {
          display: none; }
      .about__block .about__row h1 {
        margin-bottom: 20px; }
      .about__block .about__row h3 {
        margin-bottom: 20px; }
    .about__block .about__img {
      width: 450px;
      padding-right: 20px; }
    .about__block .right {
      width: calc(100% - 450px); } }
  @media screen and (max-width: 768px) {
    .about__block {
      flex-direction: column-reverse;
      align-items: center; }
      .about__block .right {
        width: 100%;
        margin-bottom: 20px; }
      .about__block .about__row.first {
        margin-bottom: 30px; }
      .about__block .about__img {
        padding-right: 0; }
        .about__block .about__img img {
          max-width: 100%; } }
  @media screen and (max-width: 500px) {
    .about__block .about__img {
      width: 100%; } }

/* main about */
/* main advantages */
.advantages .container {
  padding: 85px 15px; }

@media screen and (max-width: 1440px) {
  .advantages .container {
    padding: 75px 15px; } }

@media screen and (max-width: 1200px) {
  .advantages .container {
    padding: 50px 15px; } }

@media screen and (max-width: 768px) {
  .advantages .container {
    padding: 40px 15px; } }

.advantages__block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -15px; }
  .advantages__block .advantages__item {
    background-color: rgba(2, 88, 115, 0.5);
    width: calc(33.33% - 30px);
    padding: 45px 20px;
    margin: 15px 15px;
    text-align: center;
    border-radius: 5px; }
    .advantages__block .advantages__item h5 {
      margin: 25px 0 15px; }
    .advantages__block .advantages__item p {
      margin: 0; }
  @media screen and (max-width: 1200px) {
    .advantages__block {
      margin: 0 -7.5px -7.5px; }
      .advantages__block .advantages__item {
        width: calc(33.33% - 15px);
        margin: 7.5px; }
        .advantages__block .advantages__item h5 {
          margin: 20px 0 15px; } }
  @media screen and (max-width: 1024px) {
    .advantages__block .advantages__item {
      background-color: rgba(2, 88, 115, 0.7);
      padding: 30px 15px; }
      .advantages__block .advantages__item h5 {
        margin: 15px 0; } }
  @media screen and (max-width: 600px) {
    .advantages__block .advantages__item {
      width: calc(50% - 15px); } }
  @media screen and (max-width: 420px) {
    .advantages__block {
      margin: 0 0 -15px; }
      .advantages__block .advantages__item {
        width: 100%;
        margin: 0 0 15px; } }

/* main advantages */
/* main news */
.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -30px; }
  .news__block .news__item {
    width: calc(50% - 15px);
    background-color: #eef8ff;
    color: #333333;
    overflow: hidden;
    border-radius: 5px;
    margin: 0 0 30px; }
    .news__block .news__item .news__img {
      position: relative;
      height: 315px;
      overflow: hidden; }
      .news__block .news__item .news__img:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(28, 49, 68, 0.7);
        transition: 300ms;
        opacity: 0; }
      .news__block .news__item .news__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
    .news__block .news__item .news__info {
      padding: 35px 40px; }
      .news__block .news__item .news__info p {
        margin-bottom: 0; }
    .news__block .news__item .date {
      display: block;
      color: #7b7f82;
      transition: 300ms; }
    .news__block .news__item .more {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: 1; }
    .news__block .news__item h3 {
      color: #1c3144;
      margin: 20px 0;
      transition: 300ms; }
    .news__block .news__item:hover {
      background-color: #1099a1;
      color: #ffffff; }
      .news__block .news__item:hover .news__img:after {
        opacity: 1; }
      .news__block .news__item:hover .news__img img {
        transform: scale(1.1); }
      .news__block .news__item:hover .more {
        opacity: 1; }
      .news__block .news__item:hover .date {
        color: #fff; }
      .news__block .news__item:hover h3 {
        color: #ffba08; }
  @media screen and (max-width: 1200px) {
    .news__block .news__item {
      width: calc(50% - 7.5px); }
      .news__block .news__item .news__info {
        padding: 25px 30px; }
      .news__block .news__item h3 {
        margin: 15px 0; } }
  @media screen and (max-width: 1024px) {
    .news__block .news__item .news__img {
      height: 250px; }
    .news__block .news__item .news__info {
      padding: 20px 25px; } }
  @media screen and (max-width: 768px) {
    .news__block .news__item .news__img {
      height: 180px; }
    .news__block .news__item .news__info {
      padding: 20px; } }
  @media screen and (max-width: 480px) {
    .news__block {
      margin: 0 0 -15px; }
      .news__block .news__item {
        width: 100%;
        margin: 0 0 15px; }
        .news__block .news__item .news__img {
          height: 240px; } }
  @media screen and (max-width: 420px) {
    .news__block .news__item .news__img {
      height: 200px; } }
  @media screen and (max-width: 360px) {
    .news__block .news__item .news__img {
      height: 180px; } }

/* main news */
/* main faq */
.faq {
  padding-bottom: 120px; }
  .faq .container {
    padding-top: 85px; }
  .faq .btns__block {
    position: relative;
    top: 0;
    margin: 35px 0 80px; }
  .faq .container {
    padding-bottom: 0; }
  .faq .inst__slider .slick-slide {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    margin: 0 15px; }
    .faq .inst__slider .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(2, 88, 115, 0.7);
      transition: 300ms;
      opacity: 0; }
    .faq .inst__slider .slick-slide:before {
      content: '\f16d';
      font-family: 'fontAwesome';
      font-size: 34px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: 300ms;
      opacity: 0; }
    .faq .inst__slider .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .faq .inst__slider .slick-slide:hover:after {
      opacity: 1; }
    .faq .inst__slider .slick-slide:hover:before {
      opacity: 1; }
  @media screen and (max-width: 1440px) {
    .faq {
      padding-bottom: 100px; }
      .faq .container {
        padding-top: 75px; } }
  @media screen and (max-width: 1366px) {
    .faq .btns__block {
      width: 100%;
      left: 0px;
      right: 0px; } }
  @media screen and (max-width: 1200px) {
    .faq .container {
      padding-top: 50px; }
    .faq .btns__block {
      margin-bottom: 50px; }
    .faq .inst__slider .slick-slide {
      margin: 0 10px; }
      .faq .inst__slider .slick-slide:before {
        font-size: 30px; } }
  @media screen and (max-width: 1024px) {
    .faq {
      padding-bottom: 60px; }
      .faq .container {
        padding-top: 30px; }
      .faq .inst--title {
        margin-top: 35px; } }
  @media screen and (max-width: 768px) {
    .faq {
      padding-bottom: 40px; } }
  @media screen and (max-width: 480px) {
    .faq .inst--title > img {
      width: 28px !important; } }
  @media screen and (max-width: 360px) {
    .faq .inst--title > img {
      width: 24px !important; } }

/* main faq */
/* main content */
/* page content */
.page .news1 .date, .page .news .date {
  color: #ffba08; }

.page .page_nav .active {
  color: #ffba08; }

.page #ask-form {
  max-width: 350px; }

.page .write {
  color: #ffba08;
  text-decoration: none; }
  .page .write:hover {
    text-decoration: underline; }

.page .back_link {
  margin: 25px 0; }

.page p a {
  color: #ffba08;
  text-decoration: underline; }
  .page p a:hover {
    text-decoration: none; }

.page .h2 a {
  font-size: 16px;
  color: #ffba08; }

@media screen and (max-width: 1024px) {
  .page #photos-list td img {
    width: 100%;
    height: auto; } }

@media screen and (max-width: 480px) {
  .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
    width: 100% !important;
    height: auto !important;
    display: block !important;
    float: none !important;
    margin: 0 0 10px 0 !important; }
  .page #page-text p a img {
    width: auto !important;
    max-width: 100% !important; }
  .page #photos-list td {
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  height: 455px;
  position: relative;
  font-size: 16px;
  padding: 265px 0 0 0;
  margin-bottom: 80px;
  line-height: 1; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 155px;
    left: 50%;
    margin-left: -570px; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #fff;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #ffba08; }
        .breadcrumbs ul li a:hover {
          color: #fff; }
      .breadcrumbs ul li:after {
        content: '\f105';
        font-family: 'fontAwesome';
        margin: 0px 10px 0px 11px;
        color: #fff;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  .breadcrumbs h1 {
    color: #fff; }
  .breadcrumbs > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .breadcrumbs.second {
    height: auto;
    padding: 155px 0 0 0;
    margin-bottom: 35px; }
    .breadcrumbs.second ul {
      position: relative;
      top: 0;
      left: 0;
      margin: 0; }
      .breadcrumbs.second ul li {
        color: #999999; }
        .breadcrumbs.second ul li:after {
          color: #999999; }
        .breadcrumbs.second ul li a:hover {
          color: #999999; }
    .breadcrumbs.second h1 {
      font-size: 48px;
      color: #1c3144;
      margin: 50px 0 0 0; }
  @media screen and (max-width: 1440px) {
    .breadcrumbs {
      padding-top: 220px;
      height: 370px;
      margin-bottom: 50px; }
      .breadcrumbs ul {
        top: 125px; }
      .breadcrumbs.second {
        padding-top: 115px; }
        .breadcrumbs.second h1 {
          font-size: 38px;
          margin-top: 30px; } }
  @media screen and (max-width: 1170px) {
    .breadcrumbs ul {
      left: 15px;
      margin: 0; } }
  @media screen and (max-width: 1200px) {
    .breadcrumbs h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    .breadcrumbs {
      padding: 0;
      height: auto;
      font-size: 14px;
      margin-bottom: 20px; }
      .breadcrumbs ul {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 15px;
        margin-bottom: 15px; }
        .breadcrumbs ul li {
          color: #999999; }
          .breadcrumbs ul li:after {
            color: #999;
            margin-right: 8px;
            margin-left: 8px; }
      .breadcrumbs > img {
        display: block;
        position: relative;
        height: auto; }
      .breadcrumbs h1 {
        font-size: 28px;
        color: #1c3144;
        margin-bottom: 0; }
      .breadcrumbs.second {
        padding-top: 15px;
        margin-bottom: 20px; }
        .breadcrumbs.second h1 {
          font-size: 28px;
          margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs h1 {
      font-size: 24px; }
    .breadcrumbs.second h1 {
      font-size: 24px; } }
  @media screen and (max-width: 600px) {
    .breadcrumbs h1 {
      font-size: 22px; }
    .breadcrumbs.second h1 {
      font-size: 22px; } }

/* breadcrumbs */
/* page about */
.m-t {
  margin-top: 80px; }
  @media screen and (max-width: 1440px) {
    .m-t {
      margin-top: 60px; } }
  @media screen and (max-width: 1024px) {
    .m-t {
      margin-top: 50px; } }
  @media screen and (max-width: 768px) {
    .m-t {
      margin-top: 35px; } }

.page .cat__block .cat__item {
  margin-bottom: 30px; }

@media screen and (max-width: 1200px) {
  .page .cat__block .cat__item {
    margin-bottom: 20px; } }

.pagination {
  line-height: 1;
  margin-top: 15px; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pagination ul li {
      margin: 2.5px; }
      .pagination ul li a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "GilroySB";
        font-size: 18px;
        text-align: center;
        color: #1c3144;
        border-radius: 3px; }
        .pagination ul li a:hover {
          background-color: #eef8ff;
          color: #1099a1; }
      .pagination ul li.active a {
        background-color: #1099a1;
        color: #fff; }
      .pagination ul li.prev a:after {
        content: '\f100';
        font-family: 'fontAwesome'; }
      .pagination ul li.next a:after {
        content: '\f101';
        font-family: 'fontAwesome'; }
  @media screen and (max-width: 1440px) {
    .pagination ul li a {
      width: 36px;
      height: 36px;
      font-size: 16px; } }
  @media screen and (max-width: 1024px) {
    .pagination ul li a {
      width: 30px;
      height: 30px;
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .pagination {
      margin-top: 10px; } }

/* page about */
/* page catalog */
.cat--unit {
  display: flex;
  background-color: #eef8ff;
  margin-bottom: 170px; }
  .cat--unit .col {
    width: 50%;
    position: relative; }
  .cat--unit .cat--unit__block {
    width: 570px;
    padding: 40px 0 40px 40px; }
  .cat--unit .price {
    display: block;
    font-family: "GilroyB";
    font-size: 24px;
    color: #1c3144;
    margin: 35px 0 20px; }
  .cat--unit .btn {
    margin-top: 25px; }
  @media screen and (max-width: 1200px) {
    .cat--unit .cat--unit__block {
      width: 100%;
      padding: 30px; }
    .cat--unit .price {
      font-size: 22px;
      margin: 30px 0 20px; } }
  @media screen and (max-width: 1024px) {
    .cat--unit {
      margin-bottom: 120px; }
      .cat--unit .price {
        font-size: 20px;
        margin: 20px 0 10px; }
      .cat--unit .btn {
        margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .cat--unit {
      flex-direction: column;
      padding: 20px 15px;
      margin-bottom: 40px; }
      .cat--unit .col {
        width: 100%; }
      .cat--unit .cat--unit__block {
        padding: 0; }
      .cat--unit .price {
        font-size: 18px; } }
  @media screen and (max-width: 600px) {
    .cat--unit {
      margin-bottom: 30px; } }

.slider--for {
  height: 100%; }
  .slider--for .slick-list, .slider--for .slick-track {
    height: 100%; }
  .slider--for .slick-slide {
    outline: none; }
    .slider--for .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  @media screen and (max-width: 768px) {
    .slider--for .slick-slide {
      border-radius: 5px; } }

.dots .slick-dots {
  bottom: -40px; }
  .dots .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 7.5px; }
    .dots .slick-dots li button {
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 100%;
      border: 1px solid transparent;
      transition: 300ms; }
      .dots .slick-dots li button:before {
        display: none; }
      .dots .slick-dots li button:after {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #ffba08;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        transition: 300ms; }
      .dots .slick-dots li button:hover {
        border: 1px solid #1099a1; }
        .dots .slick-dots li button:hover:after {
          background-color: #1099a1; }
    .dots .slick-dots li.slick-active button {
      border: 1px solid #1099a1; }
      .dots .slick-dots li.slick-active button:after {
        background-color: #1099a1; }

.slider--nav {
  width: 575px;
  position: absolute;
  bottom: -80px;
  right: 20px;
  margin: 0 !important; }
  .slider--nav .slick-slide {
    height: 100px;
    outline: none;
    margin: 0 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer; }
    .slider--nav .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider--nav .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      opacity: 0;
      transition: 300ms;
      pointer-events: none;
      z-index: 1; }
    .slider--nav .slick-slide.slick-current:after {
      opacity: 0.7; }
    .slider--nav .slick-slide:hover:after {
      background-color: #1099a1;
      opacity: 0.7; }
  .slider--nav.arrow .slick-arrow {
    top: auto;
    transform: translateY(0);
    bottom: -40px; }
    .slider--nav.arrow .slick-arrow:after {
      background: url(../img/next1.png) no-repeat center center;
      background-size: contain; }
    .slider--nav.arrow .slick-arrow.slick-prev {
      left: 10px; }
    .slider--nav.arrow .slick-arrow.slick-next {
      right: 10px; }
  @media screen and (max-width: 1200px) {
    .slider--nav {
      width: 100%;
      right: 0; }
      .slider--nav .slick-slide {
        margin: 0 7.5px; }
      .slider--nav.arrow .slick-arrow {
        bottom: -36px; }
        .slider--nav.arrow .slick-arrow.slick-prev {
          left: 7.5px; }
        .slider--nav.arrow .slick-arrow.slick-next {
          right: 7.5px; }
      .slider--nav .slick-dots li {
        margin: 0 5px; }
        .slider--nav .slick-dots li button {
          width: 22px;
          height: 22px; }
          .slider--nav .slick-dots li button:after {
            width: 10px;
            height: 10px; } }
  @media screen and (max-width: 1024px) {
    .slider--nav {
      bottom: -50px; }
      .slider--nav .slick-dots {
        display: none !important; }
      .slider--nav .slick-slide {
        height: 85px; }
      .slider--nav.arrow .slick-arrow {
        bottom: -30px; } }
  @media screen and (max-width: 768px) {
    .slider--nav {
      padding: 0 50px;
      bottom: 0;
      position: relative;
      margin: 15px 0 !important; }
      .slider--nav.arrow .slick-arrow {
        width: 30px;
        bottom: 50%;
        transform: translateY(50%); } }
  @media screen and (max-width: 600px) {
    .slider--nav {
      padding: 0 40px; }
      .slider--nav .slick-slide {
        height: 75px; }
      .slider--nav.arrow .slick-arrow.slick-prev {
        left: 0; }
      .slider--nav.arrow .slick-arrow.slick-next {
        right: 0; } }
  @media screen and (max-width: 480px) {
    .slider--nav {
      padding: 0 40px; }
      .slider--nav .slick-slide {
        height: 60px; } }
  @media screen and (max-width: 380px) {
    .slider--nav {
      padding: 0 30px; } }

.cat--txt h3 {
  margin: 35px 0 20px; }

/* page catalog */
/* page contacts */
.cts {
  display: flex;
  margin-top: -80px; }
  .cts .col {
    display: flex;
    padding: 80px 0; }
    .cts .col.left {
      width: calc(50% - 80px);
      padding-right: 30px;
      justify-content: flex-end; }
    .cts .col.right {
      width: calc(50% + 80px);
      padding-left: 80px; }
  .cts .cts__block {
    width: 460px; }
    .cts .cts__block.l {
      width: 570px; }
  .cts textarea {
    height: 120px;
    resize: none; }
  .cts .g-recaptcha {
    margin-bottom: 20px; }
  .cts .sm {
    font-size: 14px;
    margin-bottom: 20px; }
  .cts p {
    margin-bottom: 20px; }
  .cts h3 {
    margin: 0 0 30px; }
  .cts .footer__soc li {
    margin-right: 20px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0; }
    .cts .footer__soc li a {
      color: #40adb4;
      border: 1px solid #40adb4; }
  @media screen and (max-width: 1440px) {
    .cts {
      margin-top: -50px; }
      .cts .col {
        padding: 50px 0; }
      .cts h3 {
        margin-bottom: 20px; } }
  @media screen and (max-width: 1200px) {
    .cts .col {
      padding: 50px 30px !important; }
      .cts .col.left {
        width: 50%; }
      .cts .col.right {
        width: 50%; }
    .cts .cts__block {
      width: 100%; } }
  @media screen and (max-width: 1024px) {
    .cts {
      margin: 0; }
      .cts .col {
        padding: 20px 15px 30px !important; } }
  @media screen and (max-width: 768px) {
    .cts {
      flex-direction: column; }
      .cts h3 {
        margin-bottom: 15px; }
      .cts .col {
        width: 100% !important; }
        .cts .col.left {
          padding-top: 5px !important; }
      .cts .cts__block.l {
        width: 100%;
        text-align: center; }
        .cts .cts__block.l h3 {
          margin-top: 5px;
          margin-bottom: 10px; }
      .cts .g-recaptcha {
        display: table;
        margin: 0 auto 10px; }
      .cts .btn {
        margin: 0 auto; }
      .cts .footer__soc li {
        margin-right: 15px; } }
  @media screen and (max-width: 340px) {
    .cts .g-recaptcha {
      display: block;
      width: 280px; }
      .cts .g-recaptcha > div {
        transform: scale(0.915);
        transform-origin: 0 0; } }

.cts__list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "GilroySB";
  font-size: 18px;
  margin-bottom: 50px; }
  .cts__list li {
    margin: 25px 0; }
  .cts__list .col--vi:after {
    background: url(../img/viber1.svg) no-repeat center center;
    background-size: contain; }
  .cts__list .col--tg:after {
    background: url(../img/telegram1.svg) no-repeat center center;
    background-size: contain; }
  .cts__list .col--wh:after {
    color: #7aef8f; }
  .cts__list .col--phone:after {
    color: #1099a1;
    top: 3px; }
  @media screen and (max-width: 1024px) {
    .cts__list {
      font-size: 16px;
      margin-bottom: 30px; }
      .cts__list li {
        margin: 15px 0; }
      .cts__list .col--phone:after {
        top: 2px; } }

/* page contacts */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 800px;
    padding: 85px 125px;
    font-size: 18px;
    background-color: #e9f4fb;
    color: #666666;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block h3 {
      font-size: 30px;
      text-align: center;
      padding: 0 10px;
      margin: 0 0 35px; }
    .modal .modal__block h4 {
      margin: 10px 0; }
    .modal .modal__block > p {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 30px; }
    .modal .modal__block.modal__block2 form .g-recaptcha {
      float: left; }
    .modal .modal__block.modal__block2 form .btn {
      float: right;
      margin: 0px 0 0 0; }
  .modal form {
    display: block;
    text-align: left;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      width: 100%;
      min-height: 50px;
      background-color: #fff;
      border-radius: 0px;
      background-color: #fff;
      font-family: "GilroyR";
      font-size: 16px;
      color: #333333;
      border: 1px solid #dddddd;
      border-radius: 3px;
      padding: 15px 30px;
      margin-bottom: 20px;
      box-shadow: none;
      line-height: 1; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #999999; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      margin: 20px auto 0; }
    .modal form .down {
      display: flex;
      font-size: 14px;
      color: #666666;
      align-items: center; }
      .modal form .down p {
        margin: 0; }
        .modal form .down p a {
          color: #ffba08;
          text-decoration: underline; }
      .modal form .down .right {
        padding-left: 10px; }
  .modal#modal--finish .modal__block {
    width: 560px;
    padding: 60px 80px; }
  @media screen and (max-width: 1440px) {
    .modal .modal__block {
      padding: 75px 100px; }
      .modal .modal__block h3 {
        font-size: 26px;
        margin-bottom: 20px; }
      .modal .modal__block p {
        margin-bottom: 20px; } }
  @media screen and (max-width: 1366px) {
    .modal .modal__block {
      padding: 50px 80px; }
      .modal .modal__block .close {
        right: 30px;
        top: 30px; } }
  @media screen and (max-width: 1200px) {
    .modal .modal__block {
      font-size: 16px; }
      .modal .modal__block h4 {
        font-size: 18px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      font-size: 16px;
      padding: 15px 25px; } }
  @media screen and (max-width: 1024px) {
    .modal .modal__block {
      width: 600px;
      font-size: 14px;
      padding: 50px; }
      .modal .modal__block .close {
        right: 20px;
        top: 20px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block {
      width: auto;
      left: 20px;
      right: 20px;
      padding: 30px; }
      .modal .modal__block h3 {
        font-size: 22px;
        margin-bottom: 15px; }
      .modal .modal__block h4 {
        font-size: 16px; }
      .modal .modal__block .close {
        top: 15px;
        right: 15px; } }
  @media screen and (max-width: 600px) {
    .modal .modal__block h3 {
      font-size: 20px; }
    .modal .modal__block > p {
      margin: 0; }
    .modal .modal__block .close {
      top: 10px;
      right: 10px; }
    .modal form .down {
      flex-direction: column;
      text-align: center; }
      .modal form .down .col {
        width: 100%; }
      .modal form .down .right {
        padding-left: 0; }
    .modal form .g-recaptcha {
      display: table;
      float: none;
      margin: 0 auto 15px; }
    .modal#modal--finish .modal__block {
      width: auto;
      left: 20px;
      right: 20px;
      padding: 40px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px;
      padding: 20px; }
      .modal .modal__block h3 {
        font-size: 18px;
        margin-bottom: 10px; }
      .modal .modal__block.modal__block2 form .g-recaptcha {
        float: none; }
      .modal .modal__block.modal__block2 form .btn {
        float: none;
        margin: 0 auto; }
    .modal#modal--finish .modal__block {
      padding: 20px; } }
  @media screen and (max-width: 380px) {
    .modal form .g-recaptcha {
      display: block;
      width: 260px;
      margin-bottom: 0; }
      .modal form .g-recaptcha > div {
        transform: scale(0.85);
        transform-origin: 0 0; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 40px;
  display: table;
  background-color: #ffcc00;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #ffcc00;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #d9af04; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
.footer__soc {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0; }
  .footer__soc li {
    margin: 15px 0 15px 15px; }
    .footer__soc li a {
      width: 40px;
      height: 40px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid #b2cde1;
      font-size: 18px;
      overflow: hidden; }
      .footer__soc li a:hover {
        color: #fff;
        border: 1px solid transparent; }
        .footer__soc li a:hover .soc-bg {
          opacity: 1; }
      .footer__soc li a i {
        z-index: 1; }
      .footer__soc li a .fa-vk {
        font-size: 18px; }

.soc-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 300ms;
  opacity: 0; }
  .soc-bg.inst-bg {
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); }
  .soc-bg.f-bg {
    background-color: #37aee2; }
  .soc-bg.vk-bg {
    background-color: #4f7db3; }

footer {
  background: url(../img/img-24.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  font-size: 16px; }
  footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1c3144;
    opacity: 0.2; }
  footer > * {
    position: relative;
    z-index: 1; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  footer a {
    color: #fff; }
    footer a:focus {
      color: #fff; }
  footer h3, footer h4 {
    font-size: 16px;
    margin: 0;
    line-height: 1; }
  footer .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  footer .up {
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 60px; }
    footer .up .container {
      justify-content: space-between; }
  footer .footer__nav {
    display: flex; }
    footer .footer__nav li {
      margin-right: 45px; }
      footer .footer__nav li a {
        font-family: "GilroySB";
        text-transform: uppercase; }
      footer .footer__nav li.active a,
      footer .footer__nav li:hover a {
        color: #ffba08; }
  footer h2 {
    font-family: "GilroySB";
    font-size: 24px;
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 0 0 35px; }
  footer .footer__item {
    display: flex;
    width: 100%;
    font-family: "GilroySB";
    font-size: 18px;
    justify-content: space-between; }
    footer .footer__item ul li {
      margin: 30px 0; }
    footer .footer__item .btn {
      margin-top: 0px; }
  footer .f-cts {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  footer .col--email:after,
  footer .col--phone:after,
  footer .col--wh:after {
    color: #b5e5ef; }
  footer .col--phone:after {
    top: 2px; }
  footer .col--email {
    padding-right: 15px; }
    footer .col--email a {
      font-size: 16px;
      font-family: "GilroyR"; }
  footer h5, footer h6 {
    font-family: "GilroyR";
    font-size: 14px;
    color: #c6cace;
    margin: 0;
    text-transform: initial; }
  footer .artmedia a {
    display: flex;
    align-items: center;
    color: #c6cace; }
  footer .down {
    background-color: rgba(28, 49, 68, 0.3);
    margin-top: 80px; }
    footer .down .container {
      height: 60px;
      justify-content: space-between; }
  @media screen and (max-width: 1024px) {
    footer .footer__nav li {
      margin-right: 30px; }
    footer h2 {
      font-size: 20px;
      margin-bottom: 15px; }
    footer .up {
      margin-bottom: 40px; }
    footer .footer__item ul li {
      margin: 20px 0; }
    footer .down {
      margin-top: 40px; } }
  @media screen and (max-width: 768px) {
    footer .up {
      margin-bottom: 20px; }
      footer .up .container {
        padding: 15px 15px 10px;
        justify-content: center; }
    footer .down {
      margin-top: 20px; }
    footer h2 {
      font-size: 18px;
      margin-bottom: 10px; }
    footer .footer__item {
      font-size: 16px; }
    footer .footer__nav {
      width: 100%;
      justify-content: center; }
      footer .footer__nav li {
        margin: 0 15px; }
    footer .footer__soc li {
      margin: 10px 10px; } }
  @media screen and (max-width: 700px) {
    footer .down .container {
      height: auto;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 15px; }
    footer .artmedia {
      margin-top: 5px; } }
  @media screen and (max-width: 600px) {
    footer .footer__nav {
      display: none; }
    footer h2 {
      margin-bottom: 15px; }
    footer .footer__item {
      flex-direction: column; }
      footer .footer__item ul li {
        margin: 0 0 15px; }
    footer .f-item {
      width: 100%; }
    footer .f-cts {
      justify-content: flex-start;
      align-items: flex-start; } }

/* footer */

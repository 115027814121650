/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		height: 100vh;
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.2);
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.slider__block {
			width: 1140px;
			font-size: 20px;
			font-family: $medium;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			color: #fff;
            margin: 0 auto;
			transform: translateY(-50%);
			text-shadow: 0px 0px 15px rgba(0,0,0,0.2);
			z-index: 1;
			.slider__title {
				display: block;
				font-family: $bold;	
				font-size: 48px;
				margin-bottom: 15px;
			}
			.btn {
				margin-top: 50px;
			}
		}
	}
	.slick-dots {
		display: flex;
		width: auto;
		flex-direction: column;
		bottom: auto;
		top: 50%;
		left: auto;
		right: 50%;
		transform: translateY(-50%);
		margin-right: -570px;
		li {
			width: auto;
			height: auto;
			margin: 8px 0px;
			button {
				display: flex;
				width: 24px;
				height: 24px;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid transparent;
				transition: 300ms;
				padding: 0;
				&:before {
					display: none;
				}
				&:after {
					content: '';
					width: 12px;
					height: 12px;
					background-color: #fff;
					opacity: 0.7;
					border-radius: 100%;
					box-sizing: border-box;
					transition: 300ms;
					border: 3px solid transparent;
				}
				&:hover {
					&:after {
						background-color: transparent;
						border: 3px solid #fff;
						opacity: 1;
					}
				}
			}
			&.slick-active {
				button {
					border: 1px solid #fff;
					&:after {
						background-color: transparent;
						border: 3px solid #fff;
						opacity: 1;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-dots {
			width: 100%;
			flex-direction: row;
			justify-content: center;
			top: auto;
			bottom: 28px;
			left: 0;
			right: 0;
			transform: translateY(0);
		}
		.slick-dots  li {
			margin: 0 8px;
		}
		.slick-slide {
			.slider__block {
				.slider__title {
					font-size: 42px;
					line-height: 1;
				}
				.btn {
					margin-top: 30px;
				}
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-dots {
			bottom: 20px;
		}
		.slick-slide {
			.slider__block {
				width: auto;
				left: 115px;
				right: 115px;
				.slider__title {
					font-size: 36px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				&.slick-prev {
					left: 28px;
				}
				&.slick-next {
					right: 120px;
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.slick-slide {
			.slider__block {
				font-size: 18px;
				.slider__title {
					font-size: 30px;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		&.arrow {
			.slick-arrow {
				display: none!important;
				width: 35px;
				&.slick-next {
					right: 28px;
				}
			}
		}
		.slick-slide {
			height: 375px;
			.slider__block {
				font-size: 16px;
				left: 15px;
				right: 15px;
				.slider__title {
					font-size: 24px;
				}
				.btn {
					margin-top: 20px;
				}
			}
		}
		.slick-dots {
			li {
				margin: 0 5px;
				button {
					width: 20px;
					height: 20px;
					&:after {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			.slider__block {
				.slider__title {
					font-size: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			height: 300px;
			.slider__block {
				.slider__title {
					margin-bottom: 10px;
				}
				.btn {
					margin-top: 15px;
				}
			}
		}
		.slick-dots {
			bottom: 15px;
		}
	}
}

.arrow {
	.slick-arrow {
		width: 76px;
		height: 24px;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url(../img/next.png) no-repeat center center;
			transition: 300ms;
		}
		&.slick-next {
			right: 120px;
		}
		&.slick-prev {
			left: 60px;
			&:after {
				transform: rotate(-180deg);
			}
		}
		&:hover {
			&:after {
				background: url(../img/next1.png) no-repeat center center;
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-arrow {
			width: 50px;
			height: 20px;
			&:after {
				background-size: contain;
			}
			&:hover:after {
				background-size: contain;
			}
		}
	}
}

.f-btns {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	a {
		display: flex;
		width: 72px;
		height: 52px;
		align-items: center;
		padding: 0 0 0 20px;
		border-radius: 3px 0 0 3px;
		margin: 5px 0;
		img {
			width: 32px;
			height: 32px;
			object-fit: contain;
			object-position: center center;
		}
		&.wht {
			background-color: #42e960;
		}
		&.vib {
			background-color: #7360f2;
		}
		&.te {
			background-color: #37aee2;
		}
		&:hover {
			width: 120px;
		}
	}
	@media screen and (max-width: 1366px) {
		a {
			height: 46px;
			width: 58px;
			padding: 0 0 0 15px;
			img {
				width: 26px;
				height: 26px;
			}
			&:hover {
				width: 80px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		a {
			height: 40px;
			width: 45px;
			padding-left: 10px;
			img {
				width: 22px;
				height: 22px;
			}
			&:hover {
				width: 60px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		a {
			width: 30px!important;
			align-items:  center;
			justify-content: center;
			height: 30px;
			width: 30px;
			padding: 0;
			img {
				width: 18px;
				height: 18px;
			}
		}
	}
}

.btns__block {
	display: flex;
	width: 1170px;
	position: absolute;
	top: calc(100vh - 140px);
	left: 0;
	right: 0;
	margin: 0 auto;
	a {
		display: flex;
		width: calc(33.33% - 30px);
		font-family: $boldS;
		color: #fff;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 11px 10px 9px;
		margin: 0 15px;
		border-radius: 3px;
		img {
			width: 32px;
			height: 32px;
			object-fit: contain;
			object-position: center center;
			margin-right: 10px;
		}
		&.wht {
			background-color: #42e960;
		}
		&.vib {
			background-color: #7360f2;
		}
		&.te {
			background-color: #37aee2;
		}
		&:hover {
			background-color: #ffba08;
		}
	}
	@media screen and (max-width: 1366px) {
		width: auto;
		left: 105px;
		right: 105px;
		top: calc(100vh - 120px);
		a {
			width: calc(33.33% - 20px);
			font-size: 15px;
			margin: 0 10px;
			img {
				width: 26px;
				height: 26px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		a {
			width: calc(33.33% - 15px);
			font-size: 14px;
			margin: 0 7.5px;
			img {
				width: 22px;
				height: 22px;
				margin-right: 8px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		display: none;
		left: 10px;
		right: 10px;
	}

}
/* main slider */

/* main catalog */
.cat__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.cat__item {
		display: flex;
		width: calc(50% - 15px);
		flex-direction: column;
		border-radius: 4px;
		overflow: hidden;
		.cat__img {
			overflow: hidden;
			height: 310px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				transition: 300ms;
			}
			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}
		h3 {
			margin: 0 0 15px;
		}
		.cat__info {
			background-color: #eef8ff;
			padding: 30px 35px 100px;
			position: relative;
			flex: 1 0 auto;
		}
		.down {
			display: flex;
			align-items: center;
			position: absolute;
			left: 35px;
			right: 35px;
			bottom: 30px;
			.btn {
				margin-right: 20px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.cat__item {
			width: calc(50% - 7.5px);
			.cat__info {
				padding: 25px 30px 90px;
			}
			.down {
				left: 30px;
				right: 30px;
				bottom: 30px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.cat__item {
			.cat__img {
				height: 260px; 
			}
		}
	}
	@media screen and (max-width: 768px) {
		.cat__item {
			.cat__img {
				height: 200px;
			}
			.cat__info {
				padding: 20px 20px 80px;	
			}
			.down {
				left: 20px;
				bottom: 20px;
				.btn {
					margin-right: 10px;
				}
			}
		}
	}
	@media screen and (max-width: 700px) {
		.cat__item {
			width: 100%;
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	@media screen and (max-width: 360px) {
		.cat__item {
			.down {
				.btn {
					padding-left: 20px!important;
					padding-right: 20px!important;
				}
			}
		}
	}
}
.more {
	display: inline-flex;
	&:after {
		content: '\f101';
		font-family: 'fontAwesome';
		font-size: 16px;
		margin: -2px 0 0 8px;
	}
	@media screen and (max-width: 768px) {
		&:after {
			margin-left: 5px;
			margin-top: -3px;
		}
	}
}
/* main catalog */

/* main about */
.about {
	position: relative;
	> img {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -2;
	}
	@media screen and (max-width: 1024px) {
		.container {
			padding-top: 0;
		}
	}
}
.about__block {
	display: flex;
	align-items: flex-start;
	.about__img {
		width: 550px;
		padding-right: 55px;
		img {
			display: block;
			width: 100%;
		}
	}
	.right {
		width: calc(100% - 500px);
	}
	h1 {
		margin-bottom: 35px;
	}
	.about__row {
		position: relative;
		padding-right: 40px;
		&.first {
			position: relative;
			margin-bottom: 110px;
			&:after {
				content: '';
				width: 100vw;
				background-color: #eef8ff;
				position: absolute;
				top: -90px;
				right: 50%;
				bottom: -55px;
				margin-right: -305px;
				z-index: -1;
			}
		}
		h3 {
			margin-bottom: 40px;
		}
		h1 {
			margin-bottom: 30px;
		}
	}
	@media screen and (max-width: 1440px) {
		.about__row {
			&.first {
				margin-bottom: 90px;
				&:after {
					top: -70px;
					bottom: -45px;
				}
			}
		}
		.about__img {
			padding-right: 40px;
		}
	}
	@media screen and (max-width: 1024px) {
		.about__row {
			padding-right: 0;
			&.first {
				margin-bottom: 40px;
				&:after {
					display: none;
				}
			}
			h1 {
				margin-bottom: 20px;
			}
			h3 {
				margin-bottom: 20px;
			}
		}
		.about__img {
			width: 450px;
			padding-right: 20px;
		}
		.right {
			width: calc(100% - 450px);
		}
	}
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		align-items: center;
		.right {
			width: 100%;
			margin-bottom: 20px;
		}
		.about__row {
			&.first {
				margin-bottom: 30px;
			}
		}
		.about__img {
			padding-right: 0;
			img {
				max-width: 100%;
			}
		}
	}
	@media screen and (max-width: 500px) {
		.about__img {
			width: 100%;
		}
	}
}
/* main about */

/* main advantages */
.advantages {
	.container {
		padding: 85px 15px;
	}
	@media screen and (max-width: 1440px) {
		.container {
			padding: 75px 15px;
		}
	}
	@media screen and (max-width: 1200px) {
		.container {
			padding: 50px 15px;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding: 40px 15px;
		}
	}
}
.advantages__block {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px -15px;
	.advantages__item {
		background-color: rgba(2, 88, 115, 0.5);
		width: calc(33.33% - 30px);
		padding: 45px 20px;
		margin: 15px 15px;
		text-align: center;
		border-radius: 5px;
		h5 {
			margin: 25px 0 15px;
		}
		p {
			margin: 0;
		}
	}
	@media screen and (max-width: 1200px) {
		margin: 0 -7.5px -7.5px;
		.advantages__item {
			width: calc(33.33% - 15px);
			margin: 7.5px;
			h5 {
				margin: 20px 0 15px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.advantages__item {
			background-color: rgba(2, 88, 115, 0.7);
			padding: 30px 15px;
			h5 {
				margin: 15px 0;
			}
		}
	}
	@media screen and (max-width: 600px) {
		.advantages__item {
			width: calc(50% - 15px);
		}
	}
	@media screen and (max-width: 420px) {
		margin: 0 0 -15px;
		.advantages__item {
			width: 100%;
			margin: 0 0 15px;
		}
	}
}
/* main advantages */

/* main news */
.news__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -30px;
	.news__item {
		width: calc(50% - 15px);
		background-color: #eef8ff;
		color: #333333;
		overflow: hidden;
		border-radius: 5px;
		margin: 0 0 30px;
		.news__img {
			position: relative;
			height: 315px;
			overflow: hidden;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(28, 49,68,0.7);
				transition: 300ms;
				opacity: 0;
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				transition: 300ms;
			}
		}
		.news__info {
			padding: 35px 40px;
			p {
				margin-bottom: 0;
			}
		}
		.date {
			display: block;
			color: #7b7f82;
			transition: 300ms;
		}
		.more {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			z-index: 1;
		}
		h3 {
			color: #1c3144;
			margin: 20px 0;
			transition: 300ms;
		}
		&:hover {
			background-color: #1099a1;
			color: #ffffff;
			.news__img {
				&:after {
					opacity: 1;
				}
				img {
					transform: scale(1.1);
				}
			}
			.more {
				opacity: 1;
			}
			.date {
				color: #fff;
			}
			h3 {
				color: #ffba08;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.news__item {
			width: calc(50% - 7.5px);
			.news__info {
				padding: 25px 30px;
			}
			h3 {
				margin: 15px 0;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.news__item {
			.news__img {
				height: 250px;
			}
			.news__info {
				padding: 20px 25px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.news__item {
			.news__img {
				height: 180px;
			}
			.news__info {
				padding: 20px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		margin: 0 0 -15px;
		.news__item {
			width: 100%;
			margin: 0 0 15px;
			.news__img {
				height: 240px;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.news__item {
			.news__img {
				height: 200px;
			}
		}
	}
	@media screen and (max-width: 360px) {
		.news__item {
			.news__img {
				height: 180px;
			}
		}
	}
}
/* main news */

/* main faq */
.faq {
	padding-bottom: 120px;
	.container {
		padding-top: 85px;
	}
	.btns__block {
		position: relative;
		top: 0;
		margin: 35px 0 80px;
	}
	.container {
		padding-bottom: 0;
	}
	.inst__slider {
		.slick-slide {
			overflow: hidden;
			border-radius: 5px;
			position: relative;
			margin: 0 15px;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(2,88,115, 0.7);
				transition: 300ms;
				opacity: 0;
			}
			&:before {
				content: '\f16d';
				font-family: 'fontAwesome';
				font-size: 34px;
				color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
				transition: 300ms;
				opacity: 0;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
			&:hover {
				&:after {
					opacity: 1;
				}
				&:before {
					opacity: 1;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		padding-bottom: 100px;
		.container {
			padding-top: 75px;
		}
	}
	@media screen and (max-width: 1366px) {
		.btns__block {
			width: 100%;
			left: 0px;
			right: 0px;
		}
	}
	@media screen and (max-width: 1200px) {
		.container {
			padding-top: 50px;
		}
		.btns__block {
			margin-bottom: 50px;
		}
		.inst__slider .slick-slide {
			margin: 0 10px;
			&:before {
				font-size: 30px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		padding-bottom: 60px;
		.container {
			padding-top: 30px;
		}
		.inst--title {
			margin-top: 35px;
		}
	}
	@media screen and (max-width: 768px) {
		padding-bottom: 40px;
	}
	@media screen and (max-width: 480px) {
		.inst--title {
			> img {
				width: 28px!important;
			}
		}
	}
	@media screen and (max-width: 360px) {
		.inst--title {
			> img {
				width: 24px!important;
			}
		}
	}
}
/* main faq */

/* main content */